<template>
  <div class="fultServiceCenter-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="故障上报" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="processManageFormRef"
          labelWidth="200px"
          style="width: 1200px; margin: 20px auto 40px"
          :model="processManageForm"
          :rules="processManageFormRules"
          :disabled="!!viewId"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">流程信息</el-divider>

          <ml-select
            prop="companyId"
            placeholder="请选择阿米巴"
            :options="unitOptions"
            label="阿米巴:"
            keyName="companyId"
            labelName="companyName"
            valueName="companyId"
            :disabled="!!updateId || !!viewId"
            v-model="processManageForm.companyId"
            @change="selectProcessManageFormCompany"
          />
          <ml-input
            prop="processName"
            label="流程名称:"
            placeholder="请输入流程名称"
            v-model.trim="processManageForm.processName"
          />
          <ml-select
            prop="processType"
            placeholder="请选择流程分类"
            :options="typeOptions"
            label="流程分类:"
            keyName="key"
            labelName="value"
            valueName="key"
            v-model="processManageForm.processType"
            @change="selectProcessTypeForm"
          />

          <ml-input
            prop="processDesc"
            label="流程描述:"
            placeholder="请输入流程描述"
            type="textarea"
            :rows="5"
            v-model.trim="processManageForm.processDesc"
          />

          <!-- <div class="globalTitle">流程步进表</div> -->
          <!-- 流程步进器 -->
          <el-form-item label="流程节点:" prop="orderProcessUserParams">
            <!-- 添加流程按钮 -->
            <ml-button
              v-if="processManageForm.orderProcessUserParams.length <= 0 && !viewId"
              submitText="添加节点"
              submitType="text"
              submitIcon="el-icon-plus"
              :showCancel="false"
              native-type="button"
              @click-submit="clickAddProcess"
            />
          </el-form-item>
          <el-form-item v-if="processManageForm.orderProcessUserParams.length > 0">
            <el-steps
              direction="vertical"
              :active="processManageForm.orderProcessUserParams.length"
            >
              <el-step
                v-for="(item, index) in processManageForm.orderProcessUserParams"
                :key="item"
              >
                <template #title>
                  <div>
                    <div class="nodeContent-box">
                      <el-input v-model="item.nodeContent" placeholder="请输入节点描述"></el-input>
                      <el-button
                        v-if="!viewId"
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        @click="clickRemove(index)"
                      />
                    </div>
                    <div class="name-tag-box">
                      <el-tag
                        :closable="!viewId"
                        type="info"
                        v-for="(u, i) in item.processUsers"
                        :key="u.userId"
                        @close="clickTagClose(index, i)"
                      >
                        {{ u.userName }}
                      </el-tag>
                      <el-button
                        v-if="!viewId"
                        type="text"
                        icon="el-icon-plus"
                        @click="clickAddUser(index)"
                        >添加审批人</el-button
                      >
                    </div>
                  </div>
                </template>
              </el-step>
            </el-steps>
          </el-form-item>

          <!-- 添加流程按钮 -->
          <ml-button
            v-if="processManageForm.orderProcessUserParams.length > 0 && !viewId"
            submitText="添加节点"
            submitType="text"
            submitIcon="el-icon-plus"
            :showCancel="false"
            native-type="button"
            @click-submit="clickAddProcess"
          />

          <ml-button
            v-if="!viewId"
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            native-type="button"
            @click-submit="clickSubmitProcessManageAction"
            @click-cancel="clickCancelProcessManageAction"
          />
        </ml-form>
        <ml-form labelWidth="200px" style="width: 1200px; margin: 20px auto 40px">
          <ml-button
            v-if="!!viewId"
            :showSubmit="false"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            native-type="button"
            @click-cancel="clickCancelProcessManageAction"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
    <!-- 审批人-提示框 -->
    <ml-dialog
      width="1200px"
      ref="processUsersDialogRef"
      title="审批人"
      @clickSubmit="clickProcessUsersDialogSubmit"
      @clickClose="clickProcessUsersDialogClose"
      @beforeClose="clickProcessUsersDialogClose"
    >
      <template #body>
        <div style="width: 1160px">
          <div>
            <ml-form
              inline
              labelWidth="80px"
              :model="processUsersDialogForm"
              ref="processUsersDialogFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                label="帐号:"
                v-model="processUsersDialogForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                label="用户名:"
                v-model="processUsersDialogForm.userNameLike"
                :style="{ marginRight: '40px' }"
              />
              <!-- 阿米巴 -->
              <!-- <ml-select
                class="assignedPeople-select"
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="processUsersDialogForm.companyId"
                @change="selectCompany"
              /> -->
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchData"
                @click-submit="searchFn"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberData.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAll"
              @select="select"
              :row-key="row => row.userId"
              ref="processUsersDialogTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <!-- <el-table-column align="center" type="index" width="80">
                <template #default="scope">
                  {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
                </template>
              </el-table-column> -->
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberData.total"
              :page="tabberData.page"
              v-model="tabberData.page"
              :size="tabberData.size"
              @current-change="currentChange"
              @size-change="sizeChange"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, searchParams } from '@/utils'

export default {
  components: {},
  name: 'ProcessManageAction',
  setup() {
    const { commit, dispatch } = useStore()
    const router = useRouter()
    const route = useRoute()
    const { updateId, viewId } = route.query
    // const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    /* 开始 表单 **********************************/
    // 详情数据
    const processManageFormRef = ref()
    // 表单配置
    /*
      orderProcessUserParams 说明
      { nodeContent， processUsers: [{ userId, userName}]}
    */
    const processManageForm = reactive({
      companyId: '',
      processName: '',
      processDesc: '',
      processType: '',
      processTypeName: '',
      orderProcessUserParams: []
    })
    // 单位名下拉列表
    const unitOptions = ref([])

    const typeOptions = ref([])

    // 表单校验
    const orderProcessUserParamsRule = (role, val) => {
      let isTrue = false
      val.map(item => {
        if (item.nodeContent && item.processUsers.length > 0) {
          isTrue = true
        } else {
          isTrue = false
        }
      })
      return isTrue
    }
    const processManageFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴 ', trigger: 'blur' }],
      processName: [{ required: true, message: '请输入流程名称', trigger: 'blur' }],
      orderProcessUserParams: [
        { required: true, message: '请添加流程节点', trigger: 'blur' },
        { validator: orderProcessUserParamsRule, message: '请填写完整流程节点', trigger: 'blur' }
      ]
    }
    // 提交表单
    const clickSubmitProcessManageAction = () => {
      const actionName = updateId ? 'fetchAddOrderProcess' : 'fetchAddOrderProcess'
      processManageFormRef.value.CustomFormRef.validate()
        .then(() => {
          const faultServiceCenterParams = searchParams(processManageForm)
          if (updateId) {
            faultServiceCenterParams.processId = updateId
          }
          dispatch(actionName, faultServiceCenterParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              router.push({ path: '/processManage' })
            }
          })
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelProcessManageAction = () => {
      router.push({ path: '/processManage' })
    }

    const selectProcessManageFormCompany = value => {
      processUsersDialogForm.companyId = value
      processManageForm.orderProcessUserParams = []
    }

    const getProcessTypeAction = () => {
      dispatch('fetchGetDictChildren', { dictType: 'process_type' }).then(data => {
        if (data && data.code === 200) {
          typeOptions.value = data.data || []
        }
      })
    }

    const selectProcessTypeForm = value => {
      processManageForm.processType = value
      if (value) {
        const item = typeOptions.value.find(d => d.key === value)
        if (item) {
          processManageForm.processTypeName = item.value
        } else {
          processManageForm.processTypeName = ''
        }
      } else {
        processManageForm.processTypeName = ''
      }
    }

    // 查看单位信息
    const getProcessManageActionDetails = () => {
      const processId = updateId || viewId
      dispatch('fetchGetOrderProcess', processId).then(data => {
        if (data && data.code === 200 && data.data) {
          processUsersDialogForm.companyId = data.data.companyId
          processManageForm.companyId = data.data.companyId
          processManageForm.processName = data.data.processName
          processManageForm.processDesc = data.data.processDesc
          processManageForm.processType = data.data.processType
          processManageForm.processTypeName = data.data.processTypeName
          processManageForm.orderProcessUserParams = data.data.orderProcessUsers
            ? data.data.orderProcessUsers.map(item => {
                return {
                  nodeContent: item.nodeContent,
                  processUsers: item.checkUserVos,
                  nodeId: item.nodeId
                }
              })
            : []
        }
      })
    }
    // 添加流程节点
    const clickAddProcess = () => {
      processManageForm.orderProcessUserParams.push({
        nodeContent: '',
        processUsers: [],
        nodeId: ''
      })
    }
    // 删除流程节点
    const clickRemove = index => {
      processManageForm.orderProcessUserParams.splice(index, 1)
    }

    // 审批人对话框 el
    const processUsersDialogRef = ref()
    const processUsersDialogFormRef = ref()
    const processUsersDialogTableRef = ref()
    const processUsersDialogForm = reactive({
      companyId: '',
      userCodeLike: '',
      userNameLike: ''
    })

    // 选择指派人列表
    const selectData = ref()

    // 添加流程节点审批人-打开对话框
    const clickAddUser = index => {
      processUsersDialogForm.index = index
      selectData.value = processManageForm.orderProcessUserParams[index].processUsers || []
      processUsersDialogRef.value.showDialog = true
      getAssignedPeopleData()
    }
    const clickProcessUsersDialogSubmit = () => {
      const index = processUsersDialogForm.index
      processManageForm.orderProcessUserParams[index].processUsers = selectData.value
      processUsersDialogTableRef.value.clearSelection()
      // processUsersDialogForm.companyId = ''
      processUsersDialogRef.value.showDialog = false
    }
    // 删除流程节点审批人
    const clickTagClose = (index, i) => {
      processManageForm.orderProcessUserParams[index].processUsers.splice(i, 1)
    }

    const clickProcessUsersDialogClose = () => {
      processUsersDialogTableRef.value.clearSelection()
    }

    /* 结束 用户表单 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(processUsersDialogForm)
      searchDataParams.userTypes = ''
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
      if (selectData.value.length > 0) {
        selectData.value.map(item => {
          processUsersDialogTableRef.value.toggleRowSelection(item, true)
        })
      }
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getAssignedPeopleData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 重置搜索内容
    const resetSearchData = () => {
      processUsersDialogFormRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }

    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName
        }
      })
    // 选中单个
    const select = selection => {
      selectData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = getSelection(selection)
    }

    // 选中阿米巴清除
    const selectCompany = () => {
      processUsersDialogTableRef.value.clearSelection()
      selectData.value = []
      searchFn()
    }
    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      if (data.length > 0) {
        processManageForm.companyId = data[0].companyId
        processUsersDialogForm.companyId = data[0].companyId
      }

      await getProcessTypeAction()
      if (!!updateId || !!viewId) {
        await getProcessManageActionDetails()
      }
    })
    return {
      processManageForm,
      unitOptions,
      processManageFormRules,
      clickSubmitProcessManageAction,
      clickCancelProcessManageAction,
      tabberHeight,
      processManageFormRef,
      clickAddProcess,
      clickRemove,
      clickAddUser,
      clickTagClose,
      processUsersDialogRef,
      processUsersDialogForm,
      tabberData,
      processUsersDialogFormRef,
      select,
      selectAll,
      currentChange,
      searchFn,
      sizeChange,
      processUsersDialogTableRef,
      selectCompany,
      clickProcessUsersDialogSubmit,
      clickProcessUsersDialogClose,
      resetSearchData,
      selectProcessManageFormCompany,
      updateId,
      viewId,
      typeOptions,
      getProcessTypeAction,
      selectProcessTypeForm
    }
  }
}
</script>

<style lang="scss">
.fultServiceCenter-action {
  position: relative;
  .name-tag-box {
    // border: 1px solid $--border-color-base;
    border-radius: 4px;
    padding: 10px 15px;
    min-height: 60px;
    max-height: 110px;
    overflow-x: hidden;
    .el-tag {
      margin-right: 10px;
    }
  }
  .nodeContent-box {
    display: flex;
    justify-content: space-between;
    .el-button {
      margin-left: 10px;
    }
  }

  .assignedPeople-select {
    .el-input__inner {
      width: 150px;
    }
  }

  .assignedPeople-table-box {
    border-top: 1px solid $--border-color-lighter;
    padding-top: 10px;
  }
}
</style>
